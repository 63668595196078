import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './reducers';
import { ResizeEvent } from './core/store/core.actions';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    template: ` <router-outlet /> `,
    styles: [],
    standalone: false
})
export class AppComponent implements OnInit {
  constructor(private readonly store: Store<AppState>) {
    this.store.dispatch(
      new ResizeEvent({ width: window.innerWidth, height: window.innerHeight })
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.store.dispatch(
      new ResizeEvent({
        width: event.target.innerWidth,
        height: event.target.innerHeight,
      })
    );
  }

  ngOnInit(): void {
    if (window.Intercom) {
      window.intercomSettings = {
        app_id: environment.intercomAppId,
        alignment: 'right',
        horizontal_padding: 20,
        vertical_padding: 20,
      };
      window.Intercom('boot', {
        app_id: environment.intercomAppId,
      });
    }
  }
}
