import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { adobeTagEvents } from './replace/adobe-tag-events.config';
import { HelpAndSupportHelper } from '../../core/helpers/help-and-support.helper';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { environment } from '../../../environments/environment';
import { localeDefaultSelector } from '../../dictionary/store/dictionary.selectors';
import { securityCompletedSelector } from '../../core/store/core.selectors';
import { activeRecordTypeSelector } from '../../records/store/records-list.selectors';

@Component({
    selector: 'app-root',
    template: ` <router-outlet /> `,
    styles: [],
    standalone: false
})
export class TdAppComponent {
  private readonly router = inject(Router);
  private readonly store = inject(Store<AppState>);
  pageLoad = false;
  router$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    tap(() => {
      try {
        const { host, pathname } = location;
        if (
          !this.pageLoad &&
          this.store.selectSignal(securityCompletedSelector)()
        ) {
          this.pageLoad = true;
          this.pushTmsTags({
            eventType: 'pageLoad',
            event: 'pageLoad',
            page: {
              name: host + `/core/home/id`,
              section: 'publicsite',
              subsection: 'landing',
            },
            site: {
              rsid: environment.production ? 'tdct' : 'tdother',
              name: 'digitalvault',
              language: this.store.selectSignal(localeDefaultSelector)()
                .localeId,
            },
            user: {
              authenticationStatus: 'authenticated',
            },
          });
        }
        adobeTagEvents.forEach((value, key) => {
          if (HelpAndSupportHelper.checkHelpActiveSection(pathname, key)) {
            const pageName = key.includes('{{wildcard}}')
              ? key.replace(
                  '{{wildcard}}',
                  this.store.selectSignal(activeRecordTypeSelector)()?.name
                )
              : key;
            this.pushTmsTags({
              ...value,
              page: {
                ...value.page,
                name: host + pageName?.replace(/{{id}}/g, 'id'),
              },
            });
          }
        });
      } catch (e) {
        console.error('Alloy fails', e);
      }
    })
  );

  private pushTmsTags(event: {
    eventType: string;
    event: string;
    page: {
      name: string;
      section?: string;
      subsection: string;
    };
    site?: {
      rsid: string;
      name: string;
      language: string;
    };
    user?: {
      authenticationStatus: string;
    };
  }): void {
    // @ts-ignore
    window.tms_tag?.push({ ...event });
  }

  constructor() {
    this.router$.subscribe();
  }
}
