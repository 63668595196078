import { Injectable } from '@angular/core';
import { CoreEffects } from '../../../../core/store/core.effects';
import { createEffect, ofType } from '@ngrx/effects';
import {
  BrandingConfigLoaded,
  CoreActionsTypes,
  SideDrawerBrandingRequested,
} from '../../../../core/store/core.actions';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ConfigService } from '../../../../core/services/config.service';
import { defaultBrandingSelector } from '../../../../core/store/core.selectors';

@Injectable()
export class SfCoreEffects extends CoreEffects {
  brandConfigLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<BrandingConfigLoaded>(CoreActionsTypes.BrandingConfigLoaded),
        tap(({ payload }) => ConfigService.setTenantStyle(payload.data))
      ),
    { dispatch: false }
  );

  sideDrawerBrandingRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SideDrawerBrandingRequested>(
        CoreActionsTypes.SideDrawerBrandingRequested
      ),
      switchMap(() => this.store.select(defaultBrandingSelector).pipe(take(1))),
      map(brandConfig => new BrandingConfigLoaded({ data: brandConfig }))
    )
  );
}
