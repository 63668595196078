import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SfWebMessagesService } from './core/services/sf-web-messages.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { gettingDictionarySelector } from '../../dictionary/store/dictionary.selectors';
import { gettingBrandingSelector } from '../../core/store/core.selectors';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    template: `
    <app-sd-progress-bar-a11y
      *ngIf="(loadingData$ | async) === true"
      progressBarType="main" />
    <div *ngIf="(loadingData$ | async) === false" class="sf-app-root">
      <router-outlet></router-outlet>
    </div>
  `,
    styles: [
        `
      .sf-app-root {
        background-color: var(--appBackground);
        height: 100%;
        width: 100%;
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SfAppComponent {
  private readonly service = inject(SfWebMessagesService);
  private readonly store = inject(Store<AppState>);
  loadingData$ = combineLatest([
    this.store.select(gettingDictionarySelector),
    this.store.select(gettingBrandingSelector),
  ]).pipe(
    map(
      ([gettingDictionary, gettingBranding]) =>
        gettingDictionary || gettingBranding
    )
  );
}
