import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { metaReducers, reducers } from './reducers';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { KeycloakAuthModule } from './clients/td/keycloak-auth/keycloak-auth.module';
import { A11yModule } from '@angular/cdk/a11y';
import { UserFlowStore } from './core/store/user-flow.store';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    A11yModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    environment.reduxToolsEnabled
      ? StoreDevtoolsModule.instrument({
          maxAge: environment.reduxMaxAge,
          logOnly: environment.production,
          serialize: true,
          connectInZone: true,
        })
      : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    StoreRouterConnectingModule.forRoot(),
    environment.keycloakDisabled ? AuthModule : KeycloakAuthModule.forRoot(),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi()), UserFlowStore],
})
export class AppModule {}
